export class OptanonConsentManager {
	private storedCookieValue: string | null;

	constructor() {
		this.storedCookieValue = this.getOptanonConsent();
		console.log("Initial value:", this.storedCookieValue);
		setTimeout(() => this.addListeners(), 2000); // not ideal
	}

	private getOptanonConsent(): string | null {
		const cookies = document.cookie;
		const optanonCookie = cookies
			.split("; ")
			.find(cookie => cookie.startsWith("OptanonConsent="));

		const groupsValue = optanonCookie
			?.match(/&groups=([^&]*)/)?.[0]
			.split("=")[1];

		const groupsSanitized = groupsValue
			?.replace(/%3A/g, ":")
			.replace(/%2C/g, ",");
		return groupsSanitized ? groupsSanitized : null;
	}

	private addListeners(): void {
		const openOneTrustBtn = document.querySelector<HTMLElement>(
			"#onetrust-pc-btn-handler"
		);
		openOneTrustBtn?.addEventListener("click", () => {
			console.log("One trust banner open");
			const saveChangesBtn = document.querySelector<HTMLInputElement>(
				".save-preference-btn-handler"
			);

			saveChangesBtn?.addEventListener("click", () => {
				console.log("One trust banner clicked");
				this.handlePoliciesChange();
			});
		});
	}

	private handlePoliciesChange(): void {
		const currentCookieValue = this.getOptanonConsent();

		if (currentCookieValue !== this.storedCookieValue) {
			console.log("Optanon consent Changed!");
			console.log("Previous value:", this.storedCookieValue);
			console.log("New value:", currentCookieValue);
			this.storedCookieValue = currentCookieValue;
		} else {
			console.log("No changes detected.");
		}
	}
}

document.addEventListener("DOMContentLoaded", () => {
	// tslint:disable-next-line: no-unused-expression
	new OptanonConsentManager();
});
